.warning {
  color: $black;
  font-size: 0.8125rem;
  display: flex;
  margin-bottom: 1rem;

  svg {
    height: 3rem;
    padding-bottom: 1rem;
    vertical-align: top;
    width: 3rem;
    g {
      stroke: $orange;
    }
  }

  span {
    margin-left: 0.25rem;
    max-width: 15rem;
  }
}
