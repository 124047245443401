.number-field {
  .field-control {
    .field-value {
      width: 10rem;

      .field-inner {
        .field-entry {
          width: 6rem !important;
        }

        .unit {
          color: $grey-light;
          padding-left: 0.25rem;
          padding-top: 0.625rem;
        }
      }
    }
  }
}
