.suggestion {
  @extend .button;
  @extend .is-secondary;

  color: $grey-light;
  display: block;
  font-family: $font-family-sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 1rem;
  padding: 0;
  text-align: left;

  &:hover {
    text-decoration: none !important;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
