.layergroup-item {
  align-items: center;
  display: flex;
  padding: 1rem;

  input {
    margin-right: 1rem;
  }

  div {
    display: table-cell;
    padding-bottom: 0.5rem;
    vertical-align: middle;
  }
}
