.measurement-debug {
  background-color: white;
  left: 1rem;
  bottom: 1rem;
  height: 20rem;
  overflow-y: scroll;
  position: absolute;
  width: 20rem;
  z-index: 5100;
}
