@import "Rows/index.scss";


.measurement-table {
  margin-bottom: 1rem;
  margin-top: 3rem;

  .measurement-table-title {
    align-items: center;
    background-color: $yellow;
    color: black;
    display: inline-flex;
    height: 2.125rem;
    margin-right: 0.5rem;
    min-width: 6rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    padding-top: 0.25rem;
  }

  .measurements-rows {
    border-top: 2px solid $yellow;
  }
}
