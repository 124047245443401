@import 'Menu/index.scss';

.header {
  &,
  .header-container{
    align-items: center;
    display: flex;
    height: $header-height;
    justify-content: space-between;
    position: relative;
    z-index: 4000;
    width: 100%;
  }

  .diagnostician-header-container{
    background: transparent;
    position: inherit;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: inherit;
  }

  * {
    pointer-events: auto;
  }

  .signout-button {
    font-size: 0.9rem;
    position: absolute;
    right: 0;
  }

  &.diagnostician-header {
    justify-content: center;

    .marque {
      height: 2.5rem;
      margin-top: 1.875rem;
    }
  }

  &.programme-header {
    background: $header-gradient;   
    color: white;
    justify-content: center;

    .diagnostician-header-container{
      @media (min-width: $tablet-width-breakpoint) {
        max-width: $desktop-sig-height;
        min-width: $min-width-app;
      }
    }

    .programme-name {
      color: $primary;
      font-family: "Noto Serif", serif;
      font-size: 1.375rem;
      white-space: nowrap;
    }

    .toggle {
      #ICONS\/ico-30-dots {
        stroke: white;
      }
    }
  }

  .left {
    @extend .is-secondary;

    height: 100%;
    margin-left: 0.75rem;
    margin-right: 0.5rem;
    padding: 10px 0 10px 0;
    
    .logo {
      border-radius: 50%;
      height: 100%;
    }
  }

  .separator {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 0;
    min-width: 0;
  }

  .to-signin {
    @extend .is-secondary;

    margin-right: 0.75rem;
  }
}
