.switch-button {
  align-items: center;
  background-color: $grey-very-very-very-lighter;
  border: 0;
  border-radius: 1rem;
  display: flex;
  font-size: 1rem;
  height: 2rem;
  position: relative;

  .slider {
    align-items: center;
    background-color: white;
    border-radius: 1rem;
    box-shadow: $box-shadow;
    color: $blue-darker;
    display: flex;
    font-weight: $font-weight-bold;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 1rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    position: absolute;
    transition: left 250ms;
    width: 50%;

    &.is-right {
      left: 50%;
    }
  }

  .behind {
    align-items: center;
    color: $primary;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    padding-right: 1.5rem;
    position: relative;
    width: 13rem;

    &.is-right {
      justify-content: flex-start;
      padding-left: 2rem;
    }
  }
}
