.no-parcel-marker {
  border: 0;
  color: $primary;
}

.parcel-marker {
  opacity: 0;
}

@mixin cta-tooltip {
  padding: 0;
  width: 18.75rem;
  z-index: 5000;
  display: flex;

  &.outside-eligibility {

    .info-address {
      color: $red;
    }
  }

  &.click-parcel {

    .over-arrow-shadow {
      .hero {
        min-height: 4.5rem;
      }

      .cta {
        color: $red;
      }
    }
  }

  .over-arrow-shadow {
    background-color: white;
    cursor: default;
    font-size: $font-size-normal;
    padding: 1.125rem;
    z-index: 1;

    .close {
      background-color: transparent;
      border: 0;
      color: $primary;
      cursor: pointer;
      font-size: $font-size-little-big;
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
    }

    .hero {

      .title {
        font-size: $font-size-normal;
        font-weight: $font-weight-bold;
      }

      .info-address {
        font-size: $font-size-smaller;
        width: 100%;
      }

      .reset {
        cursor: pointer;
        display: block;
        font-size: $font-size-smaller;
        width: min-content;
      }
    }

    .cta {
      font-size: $font-size-small;

      .info {
        margin-top: 1.5rem;
      }

      .action {
        @extend .button;
        @extend .is-primary;

        display: block;
        margin-top: 0.75rem;
        padding-top: 0.75rem;
        white-space: break-spaces;
      }
    }
  }
}

html:not([data-browser*="iPad"]):not([data-browser*="iPod"]):not([data-browser*="iPhone"]) .cta-tooltip {
  @include cta-tooltip;
}

html[data-browser*="iPad"] .cta-tooltip,
html[data-browser*="iPod"] .cta-tooltip,
html[data-browser*="iPhone"] .cta-tooltip {
  @include cta-tooltip;
}
