.measurement-row {
  display: flex;
  width: 100%;

  .measurement-column-content {
    align-items: center;
    justify-content: center;

    padding: 0.375rem;
    flex-grow: 1;
    flex-basis: 1rem;
    max-height: 6rem;
    min-height: 3rem;
    overflow: hidden;
  
    &.label {
      font-size: 0.825rem;
      font-weight: $font-weight-medium;
      height: 3rem;
      
      white-space: pre-wrap;
    }

    &.value {
      display: flex;
      justify-content: center;
    }

    &.description {
      padding-bottom: 0.5rem;
      padding-top: 0.875rem;

      span {
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
      }
    }

    &.form {
      display: flex;
      
      
    }
    &:not(:last-child) {
      border-right: 2px solid $grey-very-lighter;
    }
  }

  &:nth-child(2n) {
    background-color: $blue-very-lighter;
  }
}
