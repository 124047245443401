.entry-title-navigation {
  @extend .button;
  @extend .is-secondary;

  display: block;
  text-align: left;
  white-space: normal !important;

  &.selected {
    color: $blue-dark;
    font-weight: 600;
  }
}
