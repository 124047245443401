/* stylelint-disable selector-pseudo-class-no-unknown */

$font-colors-by-name: (
  'text-minor': #737475,
  'text-phe': #3D6DCC,
  'text-regular': black,
  'text-title': black,
);

@each $name, $font-color in $font-colors-by-name {
  :export {
    #{$name}_font-color: $font-color;
  }
}

$font-styles-by-name: (
  'text-minor': italic,
  'text-phe': bold,
  'text-regular': normal,
  'text-title': bold,
);

@each $name, $font-style in $font-styles-by-name {
  :export {
    #{$name}_font-style: $font-style;
  }
}

$font-sizes-by-name: (
  'text-minor': 12px,
  'text-phe': 16px,
  'text-regular': 14px,
  'text-title': 18px,
);

@each $name, $font-size in $font-sizes-by-name {
  :export {
    #{$name}_font-size: $font-size;
  }
}

$font-weights-by-name: (
  'text-minor': 400,
  'text-phe': 700,
  'text-regular': 400,
  'text-title': 700,
);

@each $name, $font-weight in $font-weights-by-name {
  :export {
    #{$name}_font-weight: $font-weight;
  }
}

$stroke-widths-by-name: (
  'other': 1px,
  'wall-partition': 3px,
);

@each $name, $stroke-width in $stroke-widths-by-name {
  :export {
    #{$name}_stroke-width: $stroke-width;
  }
}

.item {
  @extend .button;
  @extend .is-secondary;

  align-items: center;
  color: $black;
  display: flex;
  font-size: 0.875rem;
  height: auto;
  line-height: 0.75rem;
  padding: 0;
  margin: 0.425rem auto;
  padding-left: 0.25rem;
  text-align: left;
  white-space: normal;
  width: 100%;

  @media (min-width: $tablet-width-breakpoint) {
    font-size: 0.75rem;
  }

  &.is-disabled {
    background-color: transparent;
    opacity: 0.2;
  }

  &.is-selected {
    background-color: $blue-dark;
    color: white;
  }

  @each $name, $font-color in $font-colors-by-name {
    &.#{$name} {
      color: $font-color;
    }
  }

  @each $name, $font-size in $font-sizes-by-name {
    &.#{$name} {
      font-size: $font-size;
    }
  }

  @each $name, $font-style in $font-styles-by-name {
    &.#{$name} {
      font-style: $font-style;
    }
  }

  @each $name, $font-weight in $font-weights-by-name {
    &.#{$name} {
      font-weight: $font-weight;
    }
  }

  @each $name, $stroke-width in $stroke-widths-by-name {
    &.#{$name} {
      svg {
        line {
          stroke-width: $stroke-width;
        }
      }
    }
  }

  svg {
    margin-right: 0.5rem;
    min-width: 2rem;
    vertical-align: sub;
  }

  p {
    margin-top: 0;
  }
}
