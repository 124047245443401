$footer-height: 5rem;

.footer {
  align-items: center;
  display: flex;
  height: $footer-height;
  justify-content: center;
  padding: 0.5rem;
  position: relative;
  text-align: center;


  .version {
    bottom: 0.25rem;
    position: absolute;
    right: 1rem;
  }
}
