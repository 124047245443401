* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px; // This is REM
  min-height: 100%;
  overflow-y: auto;

  &.no-scroll {
    overflow: hidden;
  }
}

body {
  background-color: white;
  color: $font-color-normal;
  font-family: $font-family-sans-serif;
  font-size: $font-size-normal;
  font-style: normal;
  font-weight: $font-weight-regular;
  margin: 0 auto;
  min-width: 768px;
  min-height: 100%;
  -webkit-tap-highlight-color: rgba($white, 0);
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: rgba($white, 0);
  -webkit-touch-callout: none;
  width: 100%;
}
