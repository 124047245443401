.button {
  background-color: transparent;
  border: 1px solid $primary;
  border-radius: 1.5rem;
  color: $primary;
  cursor: pointer;
  font-size: $font-size-normal;
  line-height: 1.1875rem;
  padding-bottom: 0.625rem;
  padding-top: 0.625rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  &[disabled] {
    border-color: $grey-very-lighter;
    color: $grey-very-lighter;
    cursor: default;
  }

  &:hover:not([disabled]) {
    border-color: $blue-lighter;

    &:active {
      border-color: $blue-dark;
      color: $blue-dark;
    }
  }

  &.is-primary {
    background-color: $primary;
    border-color: transparent;
    box-shadow: $box-shadow;
    color: $white;
    min-height: 2.75rem;
    min-width: 6.25rem;
    padding-left: 1.875rem;
    padding-right: 1.875rem;

    &[disabled] {
      background-color: $grey-very-lighter;
      border-color: transparent;
      box-shadow: none;
      color: $white;
    }

    &:hover:not([disabled]) {
      background-color: $purple-light;
      border: 1px solid $purple-light;
      box-shadow: $box-shadow-hover;

      &:active {
        background-color: $blue-darker;
        border: 1px solid $blue-darker;
        box-shadow: none;
        color: $grey-very-lighter;
      }
    }
  }

  &.is-primary-inversed {
    background-color: $white;
    color: $primary;

    &:hover:not([disabled]) {
      color: $white;
    }
  }

  &.is-secondary {
    border-color: transparent;

    &[disabled] {
      border-color: transparent;
      color: $grey-very-lighter;
      text-decoration: none;
    }

    &:hover:not([disabled]) {
      border-color: transparent;
      text-decoration: underline;

      &:active {
        border-color: transparent;
        color: $blue-dark;
      }
    }
  }

  &.is-secondary-inversed {
    color: $white;
  }

  &.is-control {
    background-color: white;
    border: 0;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    color: $primary;
    height: 2.5rem;
    padding: 0.25rem;
    position: absolute;
    width: 2.5rem;
    z-index: 5000;
  }
}
