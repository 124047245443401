/* stylelint disable selector-pseudo-class-no-unknown */

$colors-by-name: (
  'beaver': #95705D,
  'black': #000000,
  'cerised-red': #D93252,
  'deep-cerulean': #0081AC,
  'malibu': #5AC8FA,
  'gray': #808080,
  'hot-pink': #FF60A8,
  'laser-lemon': $yellow,
  'milan': $yellow-lighter,
  'robin-s-egg-blue': #00BFBF,
  'studio': #8E44AD,
  'zest': #E67E22,
  'white': #FFFFFF
);

@each $name, $color in $colors-by-name {
  :export {
    #{$name}: $color;
  }
}

.white {
  #ICONS\/FIGURES\/ico-30-puits {
    stroke: $blue-dark;

    g {
      fill: $blue-dark;
    }

    path {
      fill: white;
    }
  }

  #ICONS\/FIGURES\/ico-30-regard {
    g {
      fill: $blue-dark;
    }

    path {
      fill: white;
    }
  }

  #ICONS\/TOOLS\/ico-30-text-minor,
  #ICONS\/TOOLS\/ico-30-text-regular,
  #ICONS\/TOOLS\/ico-30-text-title {
    path {
      fill: white;
      stroke: $black;
    }
  }
}

@each $name, $color in $colors-by-name {
  .#{$name} {
    #ICONS\/ico-30-colorSwatch {
      #goutte {
        fill: $color;
      }
    }

    #ICONS\/FIGURES\/ico-30-aeration,
    #ICONS\/FIGURES\/ico-30-autreOuvrant,
    #ICONS\/FIGURES\/ico-30-fenetre,
    #ICONS\/FIGURES\/ico-30-objetFlottant,
    #ICONS\/FIGURES\/ico-30-portail,
    #ICONS\/FIGURES\/ico-30-porte,
    #ICONS\/FIGURES\/ico-30-porte-fenetre,
    #ICONS\/FIGURES\/ico-30-trappeVisite, {
      fill: $color;
    }

    #ICONS\/FIGURES\/ico-30-accesMezzaComble,
    #ICONS\/FIGURES\/ico-30-ascenseur,
    #ICONS\/FIGURES\/ico-30-fenetre,
    #ICONS\/FIGURES\/ico-30-marchesEscalier,
    #ICONS\/FIGURES\/ico-30-pointTN,
    #ICONS\/FIGURES\/ico-30-sensEcoulement,
    #ICONS\/FIGURES\/ico-30-vitrine,
    #ICONS\/TOOLS\/ico-30-arrow,
    #ICONS\/TOOLS\/ico-30-circle,
    #ICONS\/TOOLS\/ico-30-line,
    #ICONS\/TOOLS\/ico-30-polyline,
    #ICONS\/TOOLS\/ico-30-selector,
    #ICONS\/TOOLS\/ico-30-shapes,
    #ICONS\/TOOLS\/ico-30-square,
    #ICONS\/TOOLS\/ico-30-symbols,
    #ICONS\/TOOLS\/ico-30-text,
    #ICONS\/TOOLS\/ico-30-triangle {
      stroke: $color;
    }
  }
}

.light-building {
  #ICONS\/TOOLS\/ico-30-polyline {
    #Rectangle {
      fill: $yellow-lighter;
    }
  }
}

.heavy-building {
  #ICONS\/TOOLS\/ico-30-polyline {
    #Rectangle {
      fill: $yellow;
      stroke-width: 2;
    }
  }
}

.light-building-mask {
  #ICONS\/TOOLS\/ico-30-polyline {
    #Rectangle {
      fill: $yellow-lighter;
      stroke-dasharray: 2, 2;
    }
  }
}

.heavy-building-mask {
  #ICONS\/TOOLS\/ico-30-polyline {
    #Rectangle {
      fill: $yellow;
      stroke-dasharray: 2, 2;
    }
  }
}

.white-mask {
  #ICONS\/TOOLS\/ico-30-polyline {
    #Rectangle {
      stroke-dasharray: 2, 2;
    }
  }
}
