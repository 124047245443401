.form-group .air-conditioner {
  .checkbox-field {
    margin-top: 0.75rem;
    margin-left: 0.25rem;
  }

  .fromGroundHeightNotMeasuredReason {
    margin-top: 0.75rem;
  }
}

