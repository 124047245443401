.sub-tabs {
  align-items: center;
  display: flex;
  height: 5rem;
  margin-left: 1rem;

  .sub-tab {
    @extend .anchor;

    border: 1px solid $primary;
    border-right: 0;
    color: $primary;
    padding: 0.75rem 1rem;
    padding-top: 1rem;

    &:first-child {
      border-bottom-left-radius: 1.375rem;
      border-top-left-radius: 1.375rem;
    }

    &:last-child {
      border-bottom-right-radius: 1.375rem;
      border-top-right-radius: 1.375rem;
      border-right: 1px solid $primary;
    }

    &.current {
      background-color: $blue-darker;
      color: $white;
      font-weight: 500;
    }
  }
}
