$columns-count: 12;
$gutter-width: 24px;
$column-width: 61px;
$header-height: 3.5rem;
$page-padding: 4rem;

$column-with-gutter: $gutter-width + $column-width;
$main-max-width: $columns-count * $column-with-gutter;

$bar-heights-by-body-class-name: (
  'safari': 2rem,
  'standalone': 2rem,
  'web': 0rem
);

$sketches-bar-height: 2.875rem;
$sketches-last-margin-bottom: 0.375rem;
$tabs-height: 2rem;
$desktop-sig-height: calc(100vh - #{$header-height} - #{$tabs-height} - 3*#{$sketches-bar-height} - #{$sketches-last-margin-bottom});