.dossier-hero {
  color: $primary;
  font-size: 0.75rem;
  margin-left: 1.3rem;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis;
  text-overflow: -o-ellipsis-lastline;
}
