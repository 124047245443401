.email-field, .number-field, .search-field, .text-field {
  margin-top: $form-field-margin-top;

  .field-label {
    @extend .label;

    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25rem;
    position: relative;

    .field-asterisk {
      color: $red;
      vertical-align: middle;
    }

    &.disabled {
      color: $grey-very-lighter;
    }
  }

  .field-sublabel {
    font-size: 0.813rem;
    font-style: italic;
    margin-top: 0.25rem;
  }

  .field-control {
    .field-value {
      align-items: center;
      display: flex;

      .field-inner {
        align-items: center;
        display: flex;
        overflow: hidden;
        width: 100%;

        .field-entry {
          background-color: transparent;
          border: 0;
          border-bottom: $form-border-bottom;
          border-radius: 1px;
          box-shadow: $form-box-shadow;
          font-family: $font-family-sans-serif;
          font-size: 1rem;
          height: $form-field-entry-height;
          padding-bottom: 0.25rem;
          padding-left: 0.25rem;
          padding-right: 1rem;
          padding-top: 0.75rem;
          width: 100%;

          &:focus {
            padding-left: 0.5rem;
            background-color: white;
            border: solid 1px black;
            box-shadow: inset 0 0 0 0 #0a0a0a;
          }

          &::placeholder {
            color: $grey-very-darker;
            font-style: italic;
          }

          &:disabled {
            border-bottom: solid 1px $grey-very-lighter;
            box-shadow: none;
            color: $grey-very-lighter;

            &::placeholder {
              color: $grey-very-lighter;
            }
          }

          &[readonly] {
            border-bottom: none;
            box-shadow: none;
            cursor: default;

            &:focus {
              border: 0;
              padding-left: 0.25rem;
              outline: none;
            }
          }
        }
      }
    }
  }
}
