.radios-field {
  margin-top: 2.625rem;
  
  .field-label {
    @extend .label;

    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25rem;
    position: relative;

    .field-asterisk {
      color: $red;
      vertical-align: middle;
    }

    .field-sublabel {
      display: flex;
      align-items: center;
      justify-content: left;
      margin-top: 0.625rem;
      height: 1rem;
    }
  }

  .field-control {
    margin-top: 1.25rem;

    .field-value {
      .field-inner {
        align-items: center;
        color: $black;
        display: flex;
        flex-wrap: wrap;

        .radio {
          margin-right: 0.5rem;

          .field-entry {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}
