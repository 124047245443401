
.toast {
    align-items: center;
    background-color: #FDFCFA;
    border-radius: 0 0 8.5px 8.5px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3), inset 0 1px #FFF;
    display: flex;
    justify-content: center;
    left: 50%;
    padding-bottom: 1px;
    padding-left: 3px;
    padding-right: 9px;
    position: fixed;
    top: 0;
    transform: translateX(-50%);
    z-index: 6000;
    
    .container-icon {
        align-items: center;
        display: flex;
        height: 13px;
        justify-content: center;
        margin-right: 6px;
        width: 13px;
    }
    .icon {
       &-loading {
           animation: rotate 1s linear infinite; 
            @keyframes rotate{
                to{ transform: rotate(360deg); }
            }
        }
    }
    p {
        font-size: 12px;
    }
    .toast-text {
        &-warning {
            color: #737475
        }
        &-tick {
            color: #00804A
        }
        &-loading {
            color: #3D6DCC
        }
    }
} 