.switch-layer-control {
  @extend .button;
  @extend .button.is-control;

  bottom: 12.5rem;
  right: 0.625rem;

  @media (min-width: $phone-width-breakpoint) {
    bottom: inherit;
    top: 7.5rem;
  }
}
