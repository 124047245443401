.leaflet-control-attribution {
  max-height: 1rem;
  overflow-y: scroll;
}

.leaflet-control-zoom.leaflet-control {
  border: 0;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  box-sizing: border-box;
  overflow: hidden;

  a {
    color: $primary;
    font-size: $font-size-little-little-big;
    font-style: normal;
    font-weight: $font-weight-light;
    height: 2.5rem;
    line-height: 2.75rem;
    vertical-align: middle;
    width: 2.5rem;

    &.leaflet-disabled {
      background-color: white;
      color: $grey-very-very-lighter;
    }

    &:hover {
      color: $primary;
      height: 2.5rem;
      width: 2.5rem;
      line-height: 2.75rem;

      &.leaflet-disabled {
        background-color: white;
        color: $grey-very-very-lighter;
      }
    }
  }
}

.leaflet-div-icon {
  align-items: center;
  background: transparent;
  border: 0;
  display: flex;
  justify-content: center;

  i::before {
    font-size: $font-size-bigger;
  }
}

.leaflet-tooltip {
  border-radius: $border-radius;
  font-family: $font-family-sans-serif;
  pointer-events: inherit;
  white-space: normal;
}

.leaflet-tooltip-pane {
  height: 0;
}


.leaflet-bottom {
  .leaflet-control-zoom {
    margin-bottom: 4.5rem;
  }
}


.leaflet-tooltip-top {
  z-index: 1000;

  &::after {
    @extend .leaflet-tooltip-top:before;

    border-width: 30px;
    margin-bottom: -48px;
    margin-left: -30px;
  }

  &::before {
    border-top-color: transparent;
    border-width: 30px;
    background: transparent;
    margin-bottom: -6px;
    margin-left: -30px;
    box-shadow: $box-shadow;
    transform: rotate(45deg);
  }
}
