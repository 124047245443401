@import 'Tabs.scss';

/* stylelint disable at-rule-empty-line-before */
@import 'Board/index.scss';
@import 'DossierHero.scss';
@import 'Dossiers.scss';
@import 'Programmes/index.scss';
@import 'BackgroundProcesses/index.scss';


.diagnostician {
  padding-bottom: 0;
  padding-top: 0;

  &.with-header {
    padding-bottom: 0;
    padding-top: 0;
  }

  &.with-footer.with-header {
    padding-bottom: 0;
    padding-top: 0;
  }

  .modal {
    .container {
      width: 22.5rem;

      .modal-dialog {
        background-color: $pampas;
        overflow: auto;
      }
    }
  }

  .transition {
    height: 100%;

    .fade-enter {
      opacity: 0.01;
    }

    .fade-enter.fade-enter-active {
      opacity: 1;
      transition: opacity 300ms ease-in;
    }

    .fade-exit {
      opacity: 1;
    }

    .fade-exit.fade-exit-active {
      opacity: 0.01;
      transition: opacity 0 ease-in;
    }

    .parcel-polygon {
      fill: white;
      fill-opacity: 0.5;
      fill-rule: evenodd;
      stroke: black;
      stroke-dasharray: 2 4;
      stroke-linecap: square;
      stroke-linejoin: square;
      stroke-opacity: 1;
      stroke-width: 2;
    }
  }
}
