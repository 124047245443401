.tabs-container {
  width: 100%;
  background: $header-gradient;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .tabs {
    align-items: center;
    background: transparent;   
    display: flex;
    height: $tabs-height;
    position: relative;
    width: 100%;
    @media (min-width: $tablet-width-breakpoint) {
      max-width: $desktop-sig-height;
      min-width: $min-width-app;
    }
    
    .tab {
      @extend .anchor;
  
      color: $primary;
      height: 100%;
      padding-left: 1.875rem;
      padding-right: 1.875rem;
      padding-top: 0.5rem;
  
      &.is-disabled {
        cursor: default;
        opacity: 0.25;
        pointer-events: none;
      }
  
      &.is-selected {
        background-color: white;
        color: black;
        cursor: default;
        font-weight: $font-weight-semibold;
      }
    }
  
    .marque {
      bottom: 0;
      color: $grey;
      opacity: 0.2;
      position: absolute;
      right: 0.5rem;
  
      .diagnostic {
        color: $grey;
      }
    }
  }
}
