.confirm-or-cancel-modal {
    padding: 1rem;
      
    .title {
        margin-bottom: 1.5rem;
    }
      
    .controls {
        align-items: center;
        display: flex;
        justify-content: space-between;
      
        .confirm {
            @extend .button;
            @extend .is-secondary;
        }
        .cancel {
            @extend .button;
            @extend .is-primary;
        }
    }
}