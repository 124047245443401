@import "Camera/index.scss";


.tool {
  @extend .button;

  background: transparent;
  border: 0;
  border-radius: 1.5rem;
  height: calc(100% - 0.25rem);
  padding: 0.5rem;
  position: relative;


  &.is-disabled {
    background-color: transparent;
    opacity: 0.2;
  }

  &.is-loading {
    svg {
      opacity: 0;
    }
  }

  &.is-selected {

    &:not(.color) {
      @extend .white;
    }

    background-color: $blue-darker;
    box-shadow: 0 1.875rem 3.75rem 0 rgba(0, 0, 0, 0.65);
  }

  @each $name, $stroke-width in $stroke-widths-by-name {
    &.#{$name} {
      svg {
        line {
          stroke-width: $stroke-width;
        }
      }
    }
  }

  img {
    display: block;
    height: inherit;
    margin-left: auto;
    margin-right: auto;
    width: auto;
  }
}
