.toggle {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 0.25rem;
  margin-left: 0.5rem;
  z-index: 3001;

  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
  }

  svg {
    g#ICONS\/ico-30-dots {
      path {
        stroke: $primary;
      }
    }
  }

  &.is-active {
    background-color: $white;

    svg {
      g#ICONS\/ico-30-dots {
        path {
          stroke: $primary;
        }
      }
    }
  }
}
