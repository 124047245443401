.main {
  min-height: 100vh;
  padding-bottom: 2rem;
  padding-top: 2rem;

  &.with-header, &.with-footer.with-header {
    padding-top: $header-height;
  }

  .container {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    width: 90%;

    section {
      margin-bottom: 2.5rem;

      &.fullheight {
        min-height: 100vh;
      }
    }
  }
}


@each $body-class-name, $bar-height in $bar-heights-by-body-class-name {
  .#{$body-class-name} {
    .main {
      min-height: calc(100vh - #{$bar-height});

      &.with-header {
        min-height: calc(100vh - #{$header-height} - #{$bar-height});
      }

      &.with-footer {
        min-height: calc(100vh - #{$footer-height} - #{$bar-height});
      }

      &.with-footer.with-header {
        min-height: calc(100vh - #{$footer-height} - #{$header-height} - #{$bar-height});
      }

      &.fullheight {
        height: calc(100vh - #{$bar-height});

        &.with-header {
          height: calc(100vh - #{$header-height} - #{$bar-height});
        }

        &.with-footer {
          height: calc(100vh - #{$footer-height} - #{$bar-height});
        }

        &.with-footer.with-header {
          height: calc(100vh - #{$footer-height} - #{$header-height} - #{$bar-height});
        }
      }
    }
  }
}
