.basic-auth-form {
  margin-bottom: 2.625rem;
  max-width: 18.75rem;

  .submit {
    @extend .button;
    @extend .is-primary;

    margin-top: 2.625rem;
  }
}
