.textarea-field{
  margin-top: $form-field-margin-top;

  .field-label {
    @extend .label;

    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25rem;
    position: relative;

    .field-asterisk {
      color: $red;
      vertical-align: middle;
    }
  }

  .field-control {

    .field-value {
      align-items: center;
      display: flex;
      margin-bottom: 1rem;

      .field-inner {
        align-items: center;
        //border-radius: 0.25rem;
        display: flex;
        flex: 1;
        overflow: hidden;

        .field-entry {
          background-color: transparent;
          border: 0;
          border-bottom: $form-border-bottom;
          border-radius: 0;
          box-shadow: $form-box-shadow;
          font-size: 1rem;
          font-family: $font-family-sans-serif;
          padding-left: 0.25rem;
          padding-top: 1rem;
          display: flex;
          flex: 1;
          letter-spacing: inherit;
          width: 100%;
          height: $form-field-entry-height;
          resize: none;

          &::placeholder {
            color: $grey-very-darker;
            font-style: italic;
          }

          &:focus {
            padding-left: 0.75rem;
            background-color: white;
            border: solid 1px black;
            box-shadow: inset 0 0 0 0 #0a0a0a;
          }

          &[readonly] {
            border-bottom: none;
            box-shadow: none;
            cursor: default;

            &:focus {
              border: 0;
              padding-left: 0.25rem;
              outline: none;
            }
          }
        }
      }
    }
  }
}
