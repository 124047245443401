.scale {
  bottom: 1rem;
  left: 1rem;
  position: absolute;
  z-index: 100000;

  .scale-line {
    align-items: flex-end;
    border-bottom: 5px solid $black;
    border-left: 1px solid $black;
    border-right: 1px solid $black;
    color: $black;
    display: flex;
    font-size: 1rem;
    height: 10px;
    justify-content: space-between;

    .scale-left-value {
      margin-left: -0.375rem;
    }

    .scale-right-value {
      margin-right: -0.875rem;
    }
  }
}
