@import 'Suggestions/index.scss';

$address-form-width: 24rem;
$ui-top: 1rem;

.open {
  @extend .button;
  @extend .button.is-control;

  left: 0;
  top: $ui-top
}

.address-combo-box {
  background-color: white;
  box-shadow: $box-shadow;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: left 300ms, right 300ms;
  z-index: 1000;

  @media(min-width: $phone-width-breakpoint) {
    top: $ui-top;
    transition: left 300ms;
    width: $address-form-width;
  }

  &.closed {
    left: -100vw;
    right: 100vw;

    @media(min-width: $phone-width-breakpoint) {
      left: -$address-form-width;
    }
  }

  button[type="submit"] {
    display: none;
  }

  .close {
    @extend .button;
    @extend .is-secondary;

    background-color: transparent;
    color: $primary;
    position: absolute;
    right: 0;
    top: 0;
  }

  .title {
    font-size: $font-size-little-big;
    font-weight: $font-weight-semibold;
    margin-bottom: 1.125rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    padding-top: 1.125rem;
  }

  .subtitle {
    font-size: $font-size-small;
    margin-bottom: 1.125rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }

  .field {
    align-items: center;
    background-color: $primary;
    box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.4);
    display: flex;
    height: 3.5rem;
    padding-left: 1rem;
    padding-right: 1rem;

    .underline {
      align-items: center;
      border-bottom: 1px solid white;
      color: white;
      display: flex;
      justify-content: center;
      margin-right: 1.875rem;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      position: relative;
      width: 100%;

      .loading-button {
        align-items: center;
        background-color: inherit;
        display: flex;
        min-height: inherit;
        min-width: inherit;
        opacity: 0.8;
        pointer-events: none;
        padding: 0;

        i {
          color: white;
        }
      }

      .autocomplete {
        @extend .input;

        background-color: transparent;
        border: 0;
        color: white;
        flex: 1 1 auto;
        min-width: 0;
        min-height: 0;

        &::placeholder {
          color: white;
          font-style: italic;
          opacity: 0.8;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .geolocalizer {
      @extend .button;

      background-color: transparent;
      border: 1px solid white;
      border-radius: 50%;
      color: white;
      height: 2.25rem;
      padding: 0.5rem;
      width: 2.25rem;

      i {
        vertical-align: bottom;
      }
    }
  }
}