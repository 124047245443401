@import "AskForOneMoreModal.scss";


.submit-modal {
  overflow: hidden;
  padding: 1rem;

  .question {
    margin-bottom: 1rem;
  }

  .preview {
    height: auto;
    width: 100%;
  }

  .image-loading {
    font-size: 0.825rem;
    margin-top: 1rem;
  }

  .select-field {
    margin-top: 1.5rem;
  }

  .controls {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;

    .cancel-sketch {
      @extend .button;
      @extend .is-secondary;
    }
  }

  .error {
    color: red;
    margin-top: 1rem;
  }
}
