.link {
  fill: none;
  stroke: #CCCCCC;
  stroke-width: 2px;
}

.link:hover {
  stroke-opacity: 0.5;
}

.node circle {
  fill: #FFFFFF;
  stroke: steelblue;
  stroke-width: 3px;
}

.node text {
  font: 12px sans-serif;
  font-style: italic;
  font-weight: bold;
}
