.deprecated-activities-modal {
  padding: 1rem;

  .title {
    margin-bottom: 1rem;
  }

  .activities {
    margin-bottom: 1rem;

    .show-activities {
      @extend .button;
      @extend .is-secondary;
    }

    .data {
      overflow-x: scroll;
    }
  }

  .controls {
    align-items: center;
    display: flex;
    justify-content: center;

    .ok {
      @extend .button;
      @extend .is-primary;
    }
  }
}
