.alertAppUpdate {
    border: 0.5px solid grey;
    border-radius: 10px;
    background: #fff;
    display: flex;
    font-size: 14px;
    flex-direction: column;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    padding: 0.5rem;
    position: absolute;
    right: 0;
    top: 100;
    text-align: center;
    max-width: 270px;
    z-index: 10000;
}

.alertAppUpdateBtn {
    border-radius: 10px;
    border: none;
    background-color: $primary;
    color: white;
    margin-left: auto;
    margin-right: auto;
    padding: 4px;
    margin-top: 2px;
    width: 120px;
    z-index: 10500;
}