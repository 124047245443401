@import 'Suggestion.scss';

.suggestions {
  background-color: white;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 1px 3px 0 rgba(64,45,45,0.4);
  left: 0.75rem;
  right: 0.5rem;
  top: 2rem;
  max-height: 14.25rem;
  padding: 1rem;
  overflow-y: scroll;
  position: absolute;

  .error {
    color: $red;
    font-size: $font-size-small;
  }

  .instruction {
    color: $grey;
    font-size: $font-size-small;
    font-weight: $font-weight-regular;
    margin-bottom: 1rem;
  }
}
