@import "Suggestions/index.scss";


$ui-top: 0.75rem;

.combo-box {
  background-color: white;

  .title {
    font-size: $font-size-little-big;
    font-weight: $font-weight-semibold;
    margin-bottom: 1.125rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    padding-top: 1.125rem;
  }

  .subtitle {
    font-size: $font-size-small;
    margin-bottom: 1.125rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }

  .field {
    align-items: center;
    background-color: $blue-light;
    box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.4);
    display: flex;

    .underline {
      align-items: center;
      border-bottom: 1px solid white;
      color: white;
      display: flex;
      justify-content: center;
      padding-left: 0.25rem;
      position: relative;
      width: 100%;

      .loading-button {
        align-items: center;
        border-color: transparent;
        display: flex;
        opacity: 0.8;
        pointer-events: none;
        padding: 0;
      }

      .autocomplete {
        @extend .input;

        background-color: transparent;
        border: 0;
        color: white;
        flex: 1 1 auto;
        font-family: $font-family-sans-serif;
        font-size: 1rem;
        font-weight: 400;
        height: 100%;
        width: 100%;

        &::placeholder {
          color: white;
          font-style: italic;
          opacity: 0.8;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}
