@import 'LayergroupItem.scss';

.layergroups-control {
  background: $white;
  border-radius: 0.625rem;
  box-sizing: border-box;
  left: 0.875rem;
  max-height: 30rem;
  max-width: 220px;
  min-height: 6.25rem;
  overflow-x: scroll;
  padding: 1rem;
  position: absolute;
  top: 12.5rem;
  z-index: 500;
}
