.suggestion {
  @extend .button;

  background-color: transparent;
  border-radius: 0;
  display: block;
  font-size: $font-size-small;
  font-weight: $font-weight-regular;
  margin-bottom: 1rem;
  padding: 0;
  text-align: left;

  &:last-child {
    margin-bottom: 0;
  }
}
