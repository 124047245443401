.sketch-item {
  align-items: center;
  display: flex;
  height: 100%;
  margin-right: 0.75rem;

  .select {
    @extend .button;
    @extend .is-secondary;

    &.is-selected {
      color: $blue-darker;
      font-weight: $font-weight-semibold;
    }

    &.is-selected:hover {
      text-decoration: underline;
    }

    &:hover {
      border-color: transparent !important;
    }
  }

  .toggle-downward {
    @extend .button;
    @extend .is-secondary;

    align-items: center;
    display: flex;
    height: 100%;
    margin-left: 0.5rem;
    border: none;
    opacity: 0;
    padding-left: 0;
    padding-right: 0;
    position: relative;

    &.selected {
      opacity: 1;
    }
  }

  .options-container {
    align-items: flex-end;
    background-color: transparent;
    display: flex;
    filter: drop-shadow(0 2px 6px rgba(68, 0, 112, 0.3));
    flex-direction: column;
    height: 100%;
    pointer-events:none;
    position: absolute;
    top: 0;
    width: fit-content;
    z-index: 3002;

    .options-toggle {
      background-color: $white;
      border: none;
      border-radius: 0;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 100%;
      pointer-events:auto;
      position: relative;
      width: fit-content;
    }

    .options {
      background-color: $white;
      display: flex;
      flex-direction: column;
      padding: 9px 12px;
      pointer-events:auto;
      position: relative;
      width: fit-content;

      button {
        @extend .button;
        @extend .is-secondary;
  
        align-items: center;
        border: none;
        display: flex;
        flex-direction: row;
        font-family: $font-family-sans-serif;
        font-size: 16px;
        justify-content: flex-start;
        margin: 9px 0px;
        padding: 0;
        padding-right: 18px;
        text-align: left;
        width: 100%;
       
        &:last-child {
          filter: brightness(0) saturate(100%) invert(20%) sepia(54%) saturate(4913%) hue-rotate(343deg) brightness(89%) contrast(88%);
        }
        .icon-container{
          align-items: center;
          display: flex;
          height: 30px;
          justify-content: center;
          margin-right: 12px;
          width: 30px;
          & > img {
            height: 100%;
            width: 100%;
          }
        }
        &:disabled > *,
        &:disabled:last-child {
          filter: brightness(0) saturate(100%)  invert(95%) sepia(6%) saturate(386%) hue-rotate(153deg) brightness(85%) contrast(84%);
        }
      }
    }
  }
  .align-left {
    align-items: flex-start;
  }
  .not-displayed {
    visibility: hidden;
  }
}
