.select-field {
  margin-top: $form-field-margin-top;
  width: 100%;

  .field-label {
    @extend .label;

    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25rem;
    position: relative;

    .field-asterisk {
      color: $red;
      vertical-align: middle;
    }

    .field-sublabel {
      align-items: center;
      display: flex;
      height: 1rem;
      justify-content: left;
      margin-top: 0.625rem;
    }
  }

  .field-value {
    align-items: center;
    display: flex;

    .field-inner {
      width: 100%;

      .field-entry {
        appearance: none;
        background-color: transparent;
        background-image: url("data:image/svg+xml;utf8,<svg fill='teal' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-position-x: 96%;
        background-position-y: 0.625rem;
        background-repeat: no-repeat;
        border: 0;
        border-bottom: $form-border-bottom;
        border-radius: 0;
        cursor: pointer;
        font-family: $font-family-sans-serif;
        font-size: 1rem;
        height: $form-field-entry-height;
        padding-bottom: 0.25rem;
        padding-left: 1.125rem;
        padding-right: 1.125rem;
        padding-top: 0.375rem;
        width: 100%;

        &.is-placeholder {
          color: $grey-very-darker;
          font-style: italic;
        }

        option[value=""] {
          font-style: italic;
        }

        option {
          color: $black;
          font-style: normal;
        }
      }
    }
  }


  &.with-border {
    .field-value {
      .field-inner {
        .field-entry {
          background-color: $white;
          border: 1px solid $primary;
          border-radius: 1.375rem;
        }
      }
    }
  }
}
