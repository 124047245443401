.spinner {
  align-items: center;
  background-color: white;
  border: 0;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  position: absolute;
  width: 2.5rem;
  z-index: 5100;

  button {
    border-color: transparent;
    padding: 0.375rem;
    pointer-events: none;
  }
}
