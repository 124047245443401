.ongoing-download-modal,
.download-failed-modal,
.duplication-failed-modal {
    padding: 1rem;
      
    .title {
        margin-bottom: 1rem;
    }
      
    .controls {
        align-items: center;
        display: flex;
        justify-content: center;
      
        .hide {
            @extend .button;
            @extend .is-secondary;
        }
        .ok {
            @extend .button;
            @extend .is-primary;
        }
        .icon {
            animation: rotate 1s linear infinite; 
            filter: invert(90%) sepia(16%) saturate(142%) hue-rotate(152deg) brightness(85%) contrast(89%);
            @keyframes rotate{
                to{ transform: rotate(360deg); }
            }
        }
    }
}