@import 'CtaTooltip.scss';

.eligibility {
  color: $black;
  height: 100vh;
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: inherit;

  .parcel-polygon {
    fill: $blue-lighter;
    fill-opacity: 0.5;
    fill-rule: evenodd;
    stroke: $blue-light;
    stroke-dasharray: 2 4;
    stroke-linecap: square;
    stroke-linejoin: square;
    stroke-opacity: 1;
    stroke-width: 2;
  }

  .sig {
    .spinner {
      top: 1rem;
    }
    &:focus {
      cursor:pointer;
    }
    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }

}
