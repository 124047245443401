$sketches-controls-margin: 0.75rem;
$sig-box-shadow: 0 20px 20px -20px rgba(0,0,0,0.25);
$height-before-drawing : ($header-height + $tabs-height + 2*$sketches-bar-height + $sketches-last-margin-bottom) * 16;
$size-with-margin-sketches-bar: ($sketches-bar-height + $sketches-last-margin-bottom) * 16;
:export {
  size-with-margin-sketches-bar: $size-with-margin-sketches-bar;
}

@import "Creation/index.scss";
@import "Modification/index.scss";

.sketches {
  margin: auto;
  height: 100%;
  width: 100%;

  &.drawing-sketches, &.framing-sketches {
    @media (min-width: $tablet-width-breakpoint) {
      max-width: $desktop-sig-height;
      min-width: $min-width-app;
    }

    .creation, .modification {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: $tablet-width-breakpoint) {
        min-width: calc(#{$min-width-app} - 2*#{$sketches-controls-margin});
      }
      
      .drawing, .framing {
        display: block;

        .sig-container {
          height: $tablet-height;
          max-height: $tablet-height;
          position: relative;
          max-width: $tablet-width;
          width: $tablet-width;
          z-index: 1;

          .shadow {
            bottom: 0;
            box-shadow: inset $sig-box-shadow;
            left: 0;
            position: absolute;
            pointer-events: none;
            right: 0;
            top: 0;
            width: 100%;
            z-index: 1000;
          }

          .sig {
            .main-parcel-marker {
              font-family: Arial;
              font-size: 0.875rem;
              font-weight: sans-serif;
            }
          }

          .spinner {
            bottom: inherit;
            left: 3.5rem;
            top: 0.825rem;
          }
        }
      }
    }
  }

  .leaflet-bottom {
    .leaflet-control-zoom {
      margin-bottom: 8.5rem;
    }
  }

  .switch-layer-control {
    bottom: 15.5rem;
  }
}

@each $body-class-name, $bar-height in $bar-heights-by-body-class-name {
  .#{$body-class-name} {
    .sketches {
      &.drawing-sketches, &.framing-sketches {
        .creation, .modification {
          .data, .framing {
            height: calc(100vh - #{$header-height} - #{$tabs-height} - 2*#{$sketches-bar-height} - 0.5 * #{$bar-height});
          }
        }
      }
    }
  }
}
