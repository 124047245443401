@import "Preparation/index.scss";
@import "Visit/index.scss";

.entry {
  @extend .col-unbreakable-75;

  height: 100%;
  overflow-y: scroll;

  form {
    .entry-hidden {
      display: none;
    }

    .entry-group {
      &:first-child {
        .header3 {
          margin-top: 0;
        }
      }

      &:last-child {
        min-height: 30rem;
      }

      .entry-title {
        @extend .header3;

        background-color: $mayane_grey;
        border-bottom: 2px solid $black;
        color: $black;
        margin-bottom: 3rem;
        padding-bottom: 0.875rem;
        padding-left: 1.125rem;
        padding-top: 0.75rem;
        padding-right: 1.125rem;
      }

      .entry-subtitle {
        @extend .header5;

        align-items: center;
        border-bottom: 2px solid $mayane_grey;
        display: flex;
        padding-left: 0;
        margin-bottom: 1rem;

        div {
          background-color: $mayane_grey;
          color: $black;
          font-size: 1.375rem;
          padding: 0.375rem 1.125rem;
        }
      }

      .entry-info {
        padding-left: 10px;
      }

      .email-field, .number-field, .radios-field, .select-field, .text-field, .textarea-field {
        align-items: center;
        display: table-row;
        height: 5rem;

        .field-label {
          display: table-cell;
          padding-left: 1.125rem;
          padding-right: 0.5rem;
        }

        .field-control {
          display: table-cell;
        }
      }

      .select-field {
        @extend .with-border;
      }
    }
  }
}
