@import "BasicAuthForm.scss";

.signin {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: left;

  &.with-header {
    padding-top: 0;
  }

  .welcome {
    @extend .header4;
  }

  .tip {
    @extend .paragraph;

    .mandatory {
      @extend .paragraph.small;
    }
  }
}
