.folder-form {
  text-align: center;

  input {
    @extend .input;

    margin-bottom: 1rem;
  }

  button[type="submit"] {
    @extend .button;
    @extend .is-primary;

    background-color: white;
    color: $blue-dark;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .sub-cta {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
