.app.modal-open {
  max-height: 100vh;
  overflow-y: hidden;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}


.modal {
  align-items: baseline;
  bottom: 0;
  background-color: rgba($black, 0.8);
  color: $grey-light;
  display: none;
  justify-content: center;
  left: 0;
  opacity: 0;
  right: 0;
  top: 0;
  position: absolute;
  will-change: opacity;
  z-index: 10000;

  &.active {
    display: flex;
    opacity: 1;
    // cannot just use opacity: 1 here,
    // the element is display none - not rendered, when this class gets applied
    animation: fadeIn 0.5s ease;
  }

  .container {
    align-items: center;
    display: flex;
    min-height: 100vh;
    justify-content: center;

    .modal-dialog {
      backface-visibility: hidden;
      background-color: $pampas;
      max-height: 90vh;
      overflow: scroll;
      position: relative;
      padding: 1rem;
      transition-timing-function: ease-out;
      transition-property: transform;
      width: 100%;
      will-change: transform, opacity;

      button.close {
        background-color: transparent;
        top: 1rem;
        right: 1rem;
        border: none;
        position: absolute;
        cursor: pointer;
        z-index: 10;
      }

      .modal-content {
        overflow: initial;
        width: auto;
        max-width: $desktop-width-breakpoint;
      }
    }
  }
}
