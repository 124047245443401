.fake-form {
    display: flex;
    flex-direction: column;
    height: auto;
    overflow: hidden;

    .parcel-form-action-buttons {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 20px;

        .cancel {
            @extend .button;
            @extend .is-secondary;

            background-color: transparent;
            box-shadow: none;
            color: $primary;
            font-weight: $font-weight-regular;
            margin-right: 20px;
        }

        .save {
            @extend .button;

            background-color: $primary;
            color: $white;
            font-weight: $font-weight-regular;
            width: 8rem;
        }
    }

    ::-webkit-scrollbar {
        display: none;
    }
}
