.figure-scale-switch-button {
  .behind {
    width: 8rem;

    svg {
      left: 5rem;
      position: absolute;

      g {
        stroke: $primary;
      }
    }

    &.is-right {
      svg {
        left: 1rem;
      }
    }
  }
}
