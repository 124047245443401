@import "Pan/index.scss";


$sig-box-shadow: 0 20px 20px -20px rgba(0,0,0,0.25);


.pans {
  background-color: white;
  box-shadow: $sig-box-shadow;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  left: 0;
  position: absolute;
  right: 0;
  top: calc(100% + #{$sketches-last-margin-bottom});
  z-index: 1001;
  max-height: $desktop-sig-height;
  overflow-y: auto;

  &.info, &.shape, &.stroke {
    width: 33%;

    .pan {
      width: 100%;
    }
  }

  &.info {
    left: 60%;
  }

  &.shape {
    left: 35%;
  }

  &.stroke {
    left: 25%;
  }
}
