.overlay-download-archive {
    align-items: center;
    background-color: white;
    display: flex;
    flex-direction : column;
    justify-content: center;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    position: fixed;
    top: 0;
    z-index: 4000;
    
    .icon {
        animation: rotate 1s linear infinite;
        @keyframes rotate{
            to{ transform: rotate(360deg); }
        }
        filter: invert(90%) sepia(16%) saturate(142%) hue-rotate(152deg) brightness(85%) contrast(89%);
    }
}