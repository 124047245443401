@import "PreviewModal.scss";

.snapshot-preview-button {
  @extend .button;

  align-items: center;
  border: 1px solid $primary;
  box-shadow: none;
  color: $primary;
  display: flex;
  font-size: 0.75rem;
  height: 2.25rem;
  padding: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
