$black:                       hsl(0, 0%, 4%) !default;
$blue:                        #007A9C;
$blue-dark:                   #183E4F;
$blue-darker:                 #1A0B25;
$blue-light:                  #0074E8;
$blue-neon:                   #00C5EF;
$blue-lighter:                #00C9F7;
$blue-very-lighter:           #ECF2F4;
$grey:                        #979797;
$grey-dark:                   #888888;
$grey-darker:                 #4A4A4A !default;
$grey-very-darker:            #737475;
$grey-light:                  #363636;
$grey-lighter:                #DBDBDB;
$grey-very-lighter:           #B9C5CA;
$grey-very-very-lighter:      #B3B3B3;
$grey-very-very-very-lighter: #F5F5F5;
$mayane_grey:                 #EBEBEB;
$green-neon:                  #98E856;
$orange:                      #C74F00;
$pampas:                      #F9F8F7;
$purple:                      #440070;
$purple-light:                #6200FF;
$purple-lighter:              #8C00FF;
$red:                         #E02020;
$red-light:                   #D62837;
$white:                       hsl(0, 0%, 100%) !default;
$yellow:                      #FFF666;
$yellow-darker:               #FFF000;
$yellow-lighter:              #FFFAA6;
$turquoise-neon:              #52E6AC;
$primary: $purple;

$header-gradient: linear-gradient(90deg, $blue-neon, $turquoise-neon, $green-neon); 
