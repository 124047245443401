.navigation {
  align-items: center;
  display: flex;
  height: 1.875rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  .icon {
    width: 2.5rem;
  }

  a {
    color: $primary;
    font-family: $font-family-sans-serif;
    font-size: $font-size-normal;
    font-weight: $font-weight-regular;
    text-decoration: none;
  }

  button {
    color: $primary;
    background-color: transparent;
    border: 0;
    font-family: $font-family-sans-serif;
    font-size: $font-size-normal;
    font-weight: $font-weight-regular;
  }

  .signout-button {
    position: inherit !important;
  }
}
