$gap: 12px;
$phone-width-breakpoint: 480px;
$tablet-width: 768px;
$tablet-height: 768px;
$tablet-width-breakpoint: $tablet-width + 1px;
$desktop-width-breakpoint: 1000px + (2 * $gap);
$widescreen-width-breakpoint: $desktop-width-breakpoint;
$fullhd-width-breakpoint: $desktop-width-breakpoint;
$min-width-app: 530px;

:export {
  tablet-width: $tablet-width;
  min-width-app: $min-width-app;
}
