@import '_ProgrammeItem.scss';

.programmes {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  .select-programme {
    text-align: center;

    .info {
      color: black;
      font-family: "Noto Serif", serif;
      margin-bottom: 1rem;
    }

    .sub-info {
      color: $grey-light;
      font-weight: $font-weight-light;
      margin-bottom: 1rem;
      margin-left: auto;
      margin-right: auto;
      max-width: 32rem;
    }

    .programmes-list {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 70vh;
      overflow-x: auto;
      width: 75vw;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
