@import 'OfflineModal.scss';

.report {
  height: calc(100% - #{$entries-bar-height});

  .top-bar {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 2.25rem;

    .infos-backup-status{
      margin-top: 2.25rem;
      text-align: center;
    }
  
  }

  .spinner {
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0;
    top: 50%;
  }

  .shadow {
    background-color: rgba(0, 0, 0, 0.25);
    bottom: 0;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1000;
  }

  .download-button {
    @extend .button;
    @extend .is-primary;

    align-items: center;
    box-shadow: none;
    color: $white;
    display: flex;
    height: 2.75rem;
    padding: 0;
    padding-left: 1.5rem;
    padding-right: 1.875rem;

    svg {
      margin-right: 0.5rem;
    }
  }
}
