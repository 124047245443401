.dossiers {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  .select-dossier {
    max-width: 18.75rem;

    .title {
      align-items: center;
      display: flex;
      margin-bottom: 2rem;

      .to-previous {
        background: transparent;
        border: 0;
        cursor: pointer;
        margin-left: -2.5rem;
        margin-right: 0.5rem;

        img {
          height: 2rem;
          width: 2rem;
        }
      }

      span {
        color: black;
        font-family: "Noto Serif";
        font-size: 1.375rem;
      }
    }

    .subtitle {
      color: black;
      font-size: 0.75rem;
      font-weight: $font-weight-medium;
    }

    .combo-box {
      box-shadow: inset 0 -2px 0 0 $primary;
      color: black;
      margin-bottom: 0.5rem;

      .field {
        background-color: transparent;
        background-image: linear-gradient(180deg, rgba(255,255,255,0.00) 79%, rgba(0,0,0,0.12) 100%);

        .underline {
          .loading-button {
            color: black;
            i {
              padding-top: 0.125rem;
            }
          }

          .autocomplete {
            color: black;
          }

          .suggestions {
            z-index: 1;
          }
        }

        .underline:focus-within {
          background-color: white;
          border: 1px solid black;
          box-shadow: inset 0 0 0 0 #0a0a0a;
        }
      }
    }

    .subinfo {
      color: $grey-light;
      font-size: 0.75rem;
      font-weight: $font-weight-light;
      margin-bottom: 1.5rem;
    }

    button[type="submit"] {
      @extend .button;
      @extend .is-primary;
    }
  }

}
