.sig {
  height: 100%;
  overflow: hidden;
  width: 100%;

  .leaflet-bottom {
    @media(min-width: $phone-width-breakpoint) {
      display: none;
    }
  }

  .leaflet-top {
    display: none;

    @media(min-width: $phone-width-breakpoint) {
      display: inline;
    }
  }

  .disable-mask {
    background-color: $black;
    opacity: 0.8;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 5000;
  }
  
  /* Fixes https://github.com/Leaflet/Leaflet/issues/3575 */
  .leaflet-tile-container img {
    width: 256.5px !important;
    height: 256.5px !important;
  }
}
