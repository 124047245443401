a.logo {
  font-weight: $font-weight-light;
  font-style: italic;
  color: $white;

  img {
    margin: 0.25em;
    max-height: 2.25em;
    height: 2.25em;
    vertical-align: middle;
  }
}
