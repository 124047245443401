.parcel-map-container {
    height: 400px;

    .parcel-map {
        height: 100%;
        width: 100%;

        .parcel-map-marker {
            color: red;
        }

        .parcel-map-tooltip {
            height: auto;
            width: 100px;
        }
    }
}