.redirection-to-drawing-modal {
    padding: 1rem;
  
    .title {
      margin-bottom: 1rem;
    }
  
    .controls {
      align-items: center;
      display: flex;
      justify-content: space-between;
  
  
      .no {
        @extend .button;
        @extend .is-secondary;
  
        padding-left: 0;
      }
  
      .yes {
        @extend .button;
        @extend .is-primary;
      }
    }
  }
  