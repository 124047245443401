@keyframes load {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.loading-button {
  align-items: center;
  background-color: transparent;
  display: flex;
  justify-content: center;

  &.is-primary {
    .loader {
      border-left-color: $white;
      border-top-color: $white;
    }
  }

  .loader {
    animation: load .75s infinite linear;
    border: 0.125rem solid transparent;
    border-left: 0.125rem solid $primary;
    border-radius: 100%;
    border-top: 0.125rem solid $primary;
    height: 1.5rem;
    min-width: 1.5rem;
  }

  i {
    min-width: 1.5rem;
  }
}
