.to-sketch-tool {
  text-align: center;

  button {
    @extend .button;
    @extend .is-primary;
    @extend .is-primary-inversed;

    border: 1px solid $primary;
    box-shadow: none;
  }
}
