@import 'items/AirConditioner.scss';

.measurement-form {
  text-align: left;
  padding: 0.5rem;

  .title {
    @extend .button;
    @extend .is-secondary;

    border-radius: 0;
    color: $black;
    font-family: "Noto-Serif";
    font-size: 1.625rem;
    padding-bottom: 1rem;
    text-align: left;

    p {
      margin-bottom: 0.25rem;
    }
  }

  .form-group {
    border-top: 2px solid $black;
    padding: 1rem;

    .warning {
      align-items: center;
    }
  }

  .form-footer {
    align-items: center;
    border-top: 1px solid $blue-very-lighter;
    display: flex;
    justify-content: space-between;

    .cancel {
      @extend .button;
      @extend .is-secondary;

      background-color: transparent;
      box-shadow: none;
      color: $primary;
      font-weight: $font-weight-regular;
      padding-left: 0;
      padding-right: 0;
    }

    .submit {
      @extend .button;

      background-color: $primary;
      color: $white;
      font-weight: $font-weight-regular;
      width: 8.875rem;
    }
  }
}
