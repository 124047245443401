.data {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .empty {
    align-items: center;
    color: $black;
    display: flex;
    height: 50%;
    justify-content: center;
  }

  .form-group {
    padding: 0.5rem;

    .checkbox-field,
    .number-field,
    .picture-field,
    .select-field,
    .textarea-field,
    .text-field {
      &.readonly {
        align-items: center;
        display: flex;
        margin-bottom: 0;
        min-height: 3.5rem;

        &:not(:first-child) {
          border-top: 1px solid $blue-very-lighter;
        }

        .field-label {
          width: 25%;
        }

        .field-control {
          flex: auto;

          .field-value {
            margin-bottom: 0;

            .field-inner {
              .field-entry {
                &[readonly] {
                  border-bottom: 0;
                  box-shadow: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
