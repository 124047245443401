.home {
  align-items: center;
  display: flex;
  justify-content: center;

  .navigations {
    padding: 1rem;

    .navigation {
      @extend .button;
      @extend .is-primary;

      display: block;
      margin-bottom: 1.5rem;
      padding-top: 0.825rem;
      width: 30rem;
    }
  }
}
