@import "Item.scss";


.pan {
  color: black;
  padding: 1.125rem;
  width: 33.33%;

  &:nth-child(n+4) {
    border-top: 1px solid #ECF2F4;
  }

  &:not(:nth-child(3n)) {
    border-right: 1px solid #ECF2F4;
  }

  .name {
    font-family: "Noto Seriff";
    margin-bottom: 0.5rem;
  }
}
