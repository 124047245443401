.photo-modal {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1rem;
  text-align: center;

  .title {
    margin-bottom: 1rem;
  }

  button {
    @extend .button;
    @extend .is-primary;
  }

  img {
    height: auto;
    width: 100%;
  }
}
