@import "ToForm.scss";

.measurement-column {

  &:not(:last-child) {
    border-right: 2px solid $grey-very-lighter;
  }

  .measurement-column-label {
    font-size: 0.825rem;
    font-weight: $font-weight-medium;
    height: 3rem;
    padding: 0.375rem;
    white-space: pre-wrap;
  }

  .measurement-column-content {
    &.value {
      align-items: center;
  
      display: flex;
      justify-content: center;
      height: 3rem;
      padding: 0.375rem;
      overflow: hidden;
    }

    &.description {
      align-items: center;
      min-height: 3rem;
      padding-left: 0.375rem;
      padding-right: 0.375rem;
      padding-top: 0.375rem;
      max-height: 6rem;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
    }

    &.form {
      align-items: center;
  
      display: flex;
      justify-content: center;
      height: 3rem;
      padding: 0.375rem;
      overflow: hidden;
    }

    &:nth-child(2n) {
      background-color: $blue-very-lighter;
    }
  }
}
