@import "FolderForm.scss";

.uploader {
  display: flex;
  justify-content: center;
  text-align: center;

  .container {
    width: 18rem;

    .logo {
      margin-bottom: 2rem;

      img {
        height: 5.5em;
      }
    }

    .title {
      border-top: solid $grey-light 0.125rem;
      font-weight: $font-weight-bold;
      margin-bottom: 2.5rem;
      padding-top: 0.5rem;
      text-align: left;

      .version {
        font-size: $font-size-smaller;
        font-weight: $font-weight-regular;
        padding-left: 0.5rem;
      }
    }

    .dropzone {
      background: linear-gradient(135deg, $primary 0%, #008CAC 100%);
      border-radius: 0.5rem;
      color: white;
      display: flex;
      justify-content: center;

      &.centered {
        align-items: center;
        min-height: 17rem;
        padding-top: 1rem;
      }

      .box {
        position: relative;
        width: 100%;

        .cta {
          font-size: $font-size-big;
          font-weight: $font-weight-bold;
          margin-bottom: 0.5rem;
        }

        .sub-cta {
          font-size: $font-size-normal;
          margin-bottom: 1.5rem;

          span {
            text-decoration-line: underline;
          }
        }

        .files {
          font-size: $font-size-small;
          list-style-position: inside;
          margin-bottom: 1rem;
          margin-left: 0.25rem;
          margin-right: 0.25rem;
          min-height: 6rem;
          padding: 1rem;
          text-align: left;

          li {
            margin-bottom: 0.5rem;
          }
        }

        .control {
          .send {
            @extend .is-primary;
            @extend .is-primary-inversed;

            border-color: $white;
            color: $white;
            margin-bottom: 0.25rem;
            margin-left: auto;
            margin-right: auto;
            width: 15rem;
          }

          .cancel {
            @extend .button;
            @extend .is-secondary;
            @extend .is-secondary-inversed;

            margin-bottom: 0.25rem;
          }

          .error {
            color: $red;
            font-size: $font-size-small;
            font-weight: $font-weight-bold;
            margin-bottom: 16px;
          }

          .success {
            align-items: center;
            display: flex;
            justify-content: center;
            margin-bottom: 16px;

            img {
              height: 1.25rem;
              margin-right: 1rem;
              width: 1.25rem;
            }
          }

          .reset {
            @extend .button;
            @extend .is-secondary;
            @extend .is-secondary-inversed;

            margin-bottom: 1rem;
            width: 15rem;
          }
        }
      }
    }
  }
}
