@import "Pans/index.scss";
@import "Tool/index.scss";

.tools-bar {
  align-items: center;
  background-color: $grey-very-very-very-lighter;
  border-radius: 1.5rem;
  display: flex;
  height: $sketches-bar-height;
  justify-content: space-between;
  margin-left: $sketches-controls-margin;
  margin-right: $sketches-controls-margin;
  margin-bottom: $sketches-last-margin-bottom;
  position: relative;
}
