@import 'Column/index.scss';

.measurements-rows {
  color: $black;
  display: flex;
  padding-left: 0.75rem;
  padding-top: 0.75rem;
  padding-right: 1.375rem;
}

.measurements-surveyor-rows {
  color: $black;
  padding-left: 0.75rem;
  padding-top: 0.75rem;
  padding-right: 1.375rem;
}
