@import "FigureScaleSwitchButton.scss";
@import "SnapshotPreviewButton/index.scss";

.sketch-bar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: $sketches-bar-height;
  margin-left: $sketches-controls-margin;
  margin-right: $sketches-controls-margin;

  .separator {
    flex: auto;
  }
}
