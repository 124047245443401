$font-family-sans-serif: 'IBM Plex Sans', Arial;
:export{
  font-family-sans-serif: $font-family-sans-serif;
}

$font-color-normal: $grey-dark;

$font-size-little-little-big: 1.125rem;
$font-size-little-big: 1.25rem;
$font-size-big: 1.5rem;
$font-size-bigger: 2rem;
$font-size-normal: 1rem;
$font-size-smaller: 0.75rem;
$font-size-small: 0.875rem;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
