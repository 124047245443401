.to-sketch-measurement {
  background: none;
  border: 0;
  cursor: pointer;

  &[disabled] {
    cursor: initial;
    opacity: 0.2;
  }
}
