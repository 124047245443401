.checkbox-field {
  margin-top: $form-field-margin-top;

  .field-control {

    .field-value {
      align-items: center;
      display: flex;

      .field-entry {
        border-radius: 0px;
        padding-left: 0.25rem;
        transform: scale(1.75);
      }

      span {
        @extend .label;

        color: $black;
        padding-left: 0.75rem;
        width: 100%;
      }

      .field-asterisk {
        color: $red;
        vertical-align: middle;
      }
    }
  }
}
