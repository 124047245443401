.error-modal {
  padding: 1rem;

  .title {
    margin-bottom: 1rem;
  }

  .controls {
    align-items: center;
    display: flex;
    justify-content: center;

    .ok {
      @extend .button;
      @extend .is-primary;
    }
  }
}
