@import "SketchItem.scss";
@import "InfosModal.scss";
@import "RedirectionToDrawingModal.scss";

.sketches-bar {
  align-items: center;
  display: flex;
  height: $sketches-bar-height;
  margin-left: $sketches-controls-margin;
  position: relative;

  .scroller {
    align-items: center;
    display: flex;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .disable-scroll{
    overflow-x: hidden;
  }
}
