@import "SubmitModal/index.scss";


.creation-bar {
  align-items: center;
  display: flex;
  height: $sketches-bar-height;
  justify-content: flex-start;
  margin-bottom: $sketches-last-margin-bottom;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  .create-sketch {
    @extend .button;
    @extend .is-primary;

    align-items: center;
    display: flex;
    margin-right: 1.5rem;

    span {
      margin-right: 0.5rem;
    }

    img {
      color: white;
    }
  }

  .done-with-sketches {
    @extend .button;
    @extend .is-secondary;
  }
}
