@import 'Navigation.scss';
@import 'Toggle.scss';


.overlay {
  background-color: $black;
  bottom: 0;
  left: 0;
  height: 100%;
  opacity: 0.8;
  overflow-x: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.5s;
  width: 100%;
  z-index: 3000;
}

.menu {
  display: none;
  z-index: 3001; // needed to go above the fixed CTA button on home page
  top: 0;
  left: 54%;
  right: 0;
  bottom: 0;
  opacity: 0;
  user-select: none;

  &.showing {
    display: block;
    opacity: 1;

    &:active,
    &:focus {
      outline: none !important;
    }
  }

  .navigations {
    background: white;
    min-width: 16.5rem;
    padding: 0.75rem;
    padding-right: 1.25rem;
    position: absolute;
    right: 0;
    top: $header-height;
    z-index: 100;

    .version {
      color: $grey-dark;
      font-size: 0.75rem;
      position: absolute;
      bottom: 1.25rem;
      right: 1.25rem;
    }
  }
}
