.backup-status-modal {
  margin: .5rem 14px;
  padding-top: 6px;
  
  .title {
    font-size: 21px;
    color: black;
    font-family: "Noto Serif", serif;
  }
  .infos {
    margin: 21px 0;
    font-weight: 300;
    line-height: 1.5rem;
  }
  .controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 2px 0.5px 1px 0px;

    .ok {
      font-family: $font-family-sans-serif;
      @extend .button;
      @extend .is-primary;
    }
  }
}