$entries-bar-height: 5rem;

@import 'Entry/index.scss';
@import 'Glossary/index.scss';
@import 'SubTabs.scss';

.audit {
  height: calc(100% - #{$entries-bar-height});

  .top-bar {
    align-items: center;
    display: flex;
    height: $entries-bar-height;

    .spinner {
      margin-left: 0.5rem;
      position: inherit;
    }

    .sep {
      flex: auto;
    }
  }

  .glossary-and-entry {
    display: flex;
    height: 100%;
    margin-bottom: 3rem;

    .gap {
      width: 4.5%;
    }
  }
}
