.picture-field {
  margin-top: $form-field-margin-top;

  .field-label {
    @extend .label;

    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25rem;
    position: relative;

    .field-asterisk {
      color: $red;
      vertical-align: middle;
    }
  }

  .field-control {

    .field-value {
      align-items: center;
      display: flex;
      height: $form-field-entry-height;
      justify-content: space-between;

      .field-inner {
        width: 80%;

        input[type="file"] {
          display: none;
        }
        button.cta {
          @extend .button;

          align-items: center;
          background-color: transparent;
          border: 1px solid $primary;
          color: $primary;
          display: flex;
          font-weight: $font-weight-regular;
          height: 2.75rem;
          justify-content: center;
          padding-bottom: 0.5rem;
          padding-top: 0.5rem;
          width: 100%;

          &[disabled] {
            opacity: 0.25;
          }

          span {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }

          svg {
            g {
              stroke: $primary;
            }
          }
        }
      }

      .picture {
        align-items: center;
        display: flex;
        height: $form-field-entry-height;
        border: 1px dotted $primary;
        justify-content: center;
        margin-right: 0.5rem;
        width: $form-field-entry-height;

        img {
          height: 100%;
          width: $form-field-entry-height;
        }

        .placeholder {
          background-color: transparent;
          border: 0;
          cursor: default;

          &.is-loading[disabled] {
            @extend .button;

            background-color: transparent;
            padding: 0.25rem;

            &::after {
              left: calc(50% - 0.6em);
            }
          }
        }
      }
    }
  }
}
