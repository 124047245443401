.field-error {
  color: $red-light;
  display: block;
  font-size: 0.875rem;
  margin-top: 0.375rem;
  width: 100%;


  .icon-and-messages {
    display: flex;
    text-align: left;

    .icon {
      flex: 0;
      margin-right: 0.25rem;
    }

    .messages {
      flex: 1;

      .message {
        display: block;
        margin-top: 0.25rem;
      }
    }
  }
}
