.text-field, .password-field {
  @extend .text-field;

  .mask {
    background: transparent;
    border: 0;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    outline: none;
  }
}
